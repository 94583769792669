import { Http } from '@/apis/Http'

export default {
  fetchAll(params = {}) {
    return Http.get('/admin/car-search-requests', {
      params
    })
  },
  fetchOne(id) {
    return Http.get(`/admin/car-search-requests/${id}`)
  },
  getRequestList(page, perPage, search) {
    return Http.get('/request-leas-objects/list', {
      params: {
        page,
        perPage,
        search
      }
    })
  },
  getRequestArchive(page, perPage, search) {
    return Http.get('/request-leas-objects/archive', {
      params: {
        page,
        perPage,
        search
      }
    })
  }
}
