
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'

@Component({
  name: 'RequestLeasObjectsTable'
})

export default class RequestLeasObjectsTable extends Mixins(TableMixin) {
  @Prop(Object) readonly requestLeasObjects!: any

  sortBy = []
  sortDesc = true
  simpleTable = true
}
