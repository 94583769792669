
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'EmptyStateCard'
})

export default class EmptyStateCard extends Vue {
  @Prop(String) readonly title!: string
  @Prop(String) readonly text!: string
}
