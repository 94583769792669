<template>
  <div
    v-if="!$store.getters['common/isLoading']"
    class="d-flex flex-column"
  >
    <div class="d-flex flex-column flex-sm-row mb-1">
      <div class="d-flex flex-column mb-8 mb-sm-0">
        <h5 class="h5-text mb-2">Архивные запросы</h5>
        <p class="subhead-text grey--text"> {{ countOfRequests(pagination.total) }} </p>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex flex-column mb-8 mb-sm-0">
        <v-text-field
          background-color="white"
          v-model="search"
          name="search"
          prepend-inner-icon="$search"
          placeholder="Поиск"
          outlined
        ></v-text-field>
      </div>
    </div>
    <request-leas-objects-table
      v-if="pagination.total > 0"
      :request-leas-objects="searchLeasingRequests"
    ></request-leas-objects-table>
    <empty-state-card
      v-else
      :title="'Сейчас нет архивных запросов'"
      :text="'На этой странице появятся запросы, которые уже не актуальны лизинговым компаниям'"
    ></empty-state-card>
    <v-pagination
      v-if="pagination.total > 0"
      v-model="pagination.currentPage"
      :length="pagination.lastPage"
      :total-visible="8"
    ></v-pagination>
    <!--<dealership-advantages-modal
      :show-modal.sync="showModal"
    ></dealership-advantages-modal>-->
  </div>
</template>

<script>
import { stringUtils } from '@/utils/string'

import EmptyStateCard from '@/views/components/EmptyStateCard.vue'
 import RequestLeasObjectsTable from '@/views/RequestLeasObjects/components/RequestLeasObjectsTable.vue'
// import DealershipAdvantagesModal from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/components/DealershipAdvantagesModal.vue'
// import { Watch } from 'vue-property-decorator'
import CarSearchRequest from '@/apis/CarSearchRequest'
import Logs from '@/apis/Logs'

export default {
  components: {
    EmptyStateCard,
    RequestLeasObjectsTable
  },
  data() {
    return {
      searchLeasingRequests: {
        headers: [
          {
            text: 'Номер',
            sortable: false,
            value: 'id'
          },
          {
            text: 'Дата запроса',
            sortable: false,
            value: 'date'
          },
          {
            text: 'Транспортное средство',
            sortable: false,
            value: 'car'
          },
          {
            text: 'Лизинговая компания',
            sortable: false,
            value: 'leasingCompany'
          },
          {
            text: 'Статус',
            sortable: false,
            value: 'status'
          },
          {
            text: '',
            sortable: false,
            value: 'options'
          }
        ],
        items: []
      },

      pagination: {
        total: null,
        currentPage: null,
        firstPage: null,
        lastPage: null,
        firstPageUrl: null,
        lastPageUrl: null,
        nextPageUrl: null,
        perPage: null
      },

      perPage: 10
    }
  },

  computed: {

    search: {
      get: function() {
        return this.$store.state.common.searchDsRequests
      },

      set: function(item) {
        this.$store.state.common.searchDsRequests = item
      }
    },

    showModal: {
      get: function() {
        return this.$store.state.common.showModalSearchDsRequests
      },

      set: function(item) {
        this.$store.state.common.showModalSearchDsRequests = item
      }
    }
  },

  watch: {
    onPaginationChange(val) {
      this.getRequestList(val, this.perPage)
    },
    onSearchChange() {
      this.getRequestList(1, this.perPage)
    }
  },

  created() {
    this.$setLoading(true)
    this.getRequestList(1, this.perPage)
    this.sendLogs()
  },

  mounted() {
    if (this.$route.params?.sended) {
      this.showSuccessSystemNotification('Запрос успешно отправлен')
    }
  },

  methods: {

    countOfRequests(number) {
      return stringUtils.pluralForm(number, ['запрос', 'запроса', 'запросов'])
    },

    sendLogs() {
      Logs.requestLeasObjects()
    },

    getRequestList(page, perPage) {
      CarSearchRequest.getRequestArchive(page, perPage, this.search)
        .then(response => {
          this.searchLeasingRequests.items = Object.values(response.data.data)
          this.pagination = this.fillPagination(response.data)
        })
        .catch(() => {
          // this.showErrorSystemNotification('Что-то пошло не так!')
        })
        .finally(() => {
          this.$setLoading(false)
        })
    },

    fillPagination(data) {
      return {
        total: data.total,
        currentPage: data.current_page,
        firstPage: data.first_page ?? null,
        lastPage: data.last_page ?? null,
        firstPageUrl: data.first_page_url ?? null,
        lastPageUrl: data.last_page_url ?? null,
        nextPageUrl: data.next_page_url ?? null,
        perPage: data.per_page ?? 10
      }
    }
  }
}
</script>
