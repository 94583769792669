export const stringUtils = {
  /**
   * Declines the plural form of word by the specified number
   *
   * @param {number} number
   * @param {String[]} after List of word's declensions
   * @param withNumber Flag for returning with specified number
   *
   * @return {string} Declined plural form of word
   */
  pluralForm(number: number, after: string[], withNumber = true): string {
    const n = Math.abs(number)
    const cases = [2, 0, 1, 1, 1, 2]
    const plural = withNumber ? `${number} ` : ''

    return plural + after[(n % 100 > 4 && n % 100 < 20) ? 2 : cases[Math.min(n % 10, 5)]]
  },

  priceDisplay(price: number, suffix = ' руб.'): string {
    return (price + '').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') + suffix
  }
}
